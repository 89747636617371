import { Page } from './page'
import { EntityFieldType } from './page/enums'
import { mapPageFieldsByMappings } from './page/mappers'
import { mapQuery } from './query/mappers'
import {
  NavigationReference,
  PageReference,
  Reference,
  RefsObject,
} from './types'

const getReferenceValue = (
  { type, ref, mappings = [] }: Reference,
  refs: RefsObject
) => {
  if (ref) {
    if (type === 'link') {
      const link = refs.links[ref]

      if (link) {
        return link.href
      }
    } else if (type === 'navigation') {
      const menu = refs.navigations[ref]

      if (menu) {
        return menu
      }
    } else if (type === 'query') {
      const query = refs.queries[ref]

      if (query) {
        return mapQuery(query, mappings)
      }
    } else if (type === 'string') {
      const string = refs.strings[ref]

      if (string) {
        return string
      }
    } else if (type === 'page') {
      const field = refs.page[ref as keyof PageReference]

      if (field) {
        return field
      }
    } else if (type === 'field') {
      const field = refs.fields[ref]

      if (field) {
        if (Array.isArray(field)) {
          return mapPageFieldsByMappings(field, mappings)
        }

        return field
      }
    } else {
      const file = refs.files[ref]

      if (file) {
        return file.path
      }
    }
  }

  return null
}

export const getReference = (tree: Record<string, any>, refs: RefsObject) => {
  if (tree) {
    if ('type' in tree && 'ref' in tree) {
      tree = getReferenceValue(tree as Reference, refs) as Record<string, any>
    } else {
      for (const key in tree) {
        const branch = tree[key]

        if (branch) {
          if (Array.isArray(branch)) {
            tree[key] = branch.map((leaf) => getReference(leaf, refs))
          } else if (typeof branch === 'object') {
            if ('type' in branch) {
              if (branch.type === 'static') {
                tree[key] = branch.label
              } else {
                if ('ref' in branch) {
                  tree[key] = getReferenceValue(branch, refs)
                }
              }
            } else {
              tree[key] = getReference(branch, refs)
            }
          } else {
            tree[key] = branch
          }
        }
      }
    }
  }

  return tree
}

const getNavigations = (data: any): Array<NavigationReference> => {
  const items: Array<NavigationReference> = []

  if (!data.children) return items

  for (const item of data.children) {
    items.push({
      title: item.title,
      href: item.page?.path || item.staticUrl,
      icon: item.icon,
      target: item.target,
      className: item.className,
      children: getNavigations(item),
    })
  }

  return items
}

export const getRefsObject = (
  data: Array<Record<string, any>>,
  page?: Page
): RefsObject => {
  const refs: RefsObject = {
    fields: {},
    files: {},
    navigations: {},
    links: {},
    queries: {},
    strings: {},
    page: {
      image: {},
      path: {},
      title: '',
    },
  }

  for (const ref of data) {
    if (ref.values) {
      if (ref.key === 'files') {
        for (const file of ref.values) {
          refs.files[file.id] = {
            path: file.path,
            mimetype: file.mimeType,
            extension: file.extension,
            size: file.size,
          }
        }
      } else if (ref.key === 'links') {
        for (const link of ref.values) {
          refs.links[link.id] = {
            href: link.path,
            title: link.title,
          }
        }
      } else if (ref.key === 'navigations') {
        for (const menu of ref.values) {
          refs.navigations[menu.id] = getNavigations(menu)
        }
      } else if (ref.key === 'strings') {
        for (const string of ref.values) {
          refs.strings[string.id] = string.title
        }
      } else if (ref.key === 'queries') {
        for (const query of ref.values) {
          refs.queries[query.id] = query.values.data
        }
      }
    }
  }

  if (page) {
    refs.page = {
      title: page.title,
      path: {
        href: page.path,
        children: page.title,
      },
      image: page.image,
    }
    refs.fields = page.fields || {}
  }

  return refs
}

export const getEntityRefsObject = (
  type: EntityFieldType,
  data: Array<Record<string, any>>
) => {
  if (type === EntityFieldType.Link) {
    return getRefsObject([{ key: 'links', values: data }])
  } else {
    return getRefsObject([{ key: 'files', values: data }])
  }
}
