import { request } from '../http'

import { EnvironmentContext } from '@/core/classes/environment'

import type {
  GlobalSearchResponse,
  Page,
  PageGlobalSearchParams,
  PageParams,
  PagePathParams,
} from './types'
import { mapPage, mapPagePath } from './mappers'

export const get = (environment: EnvironmentContext, params: PageParams) =>
  request<Page>(
    environment,
    {
      method: 'get',
      url: `/pages/path?path=${params.path}`,
    },
    {
      res: mapPage,
    }
  )

export const preview = (environment: EnvironmentContext, id: string) =>
  request<Page>(
    environment,
    {
      method: 'get',
      url: `/pages/preview/${id}`,
    },
    {
      res: mapPage,
    }
  )

export const findPath = (
  environment: EnvironmentContext,
  params: PagePathParams
) =>
  request<string>(
    environment,
    {
      method: 'get',
      url: `/pages/${params.language}/find-path?path=${params.path}`,
    },
    {
      res: mapPagePath,
    }
  )

export const globalSearch = (
  environment: EnvironmentContext,
  params: PageGlobalSearchParams
) =>
  request<GlobalSearchResponse>(environment, {
    method: 'get',
    url: `/pages/${params.language}/global-search?pageLength=200&search=${params.search}`,
  })
